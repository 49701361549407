import { AxiosInstance } from 'axios';

import { IAPIResponse } from '../interfaces';
import { IExercise } from '../interfaces/exercise';

const exerciseService = (httpClient: AxiosInstance) => {
  return {
    getExercise: async (exerciseIds: string[], pageSize?: number, pageNumber?: number) => {
      return httpClient
        .post<IAPIResponse<IExercise[]>>('/exercises/fetch', { exerciseIds, pageSize, pageNumber })
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get exercise', data: [] };
        });
    },

    deleteExercise: async (id: string) => {
      return httpClient
        .delete<IAPIResponse<IExercise[]>>(`/exercises/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to delete exercise', data: [] };
        });
    },

    createExercise: async (exercise: FormData) => {
      return httpClient
        .post<IAPIResponse<null>>('/exercises', exercise)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to add exercise', data: null };
        });
    },

    getExerciseById: async (id: string) => {
      return httpClient
        .get<IAPIResponse<IExercise>>(`/exercises/${id}`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get exercise', data: null };
        });
    },

    updateExercise: async (id: string, exercise: FormData) => {
      return httpClient
        .patch<IAPIResponse<any>>(`/exercises/${id}`, exercise)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to update exercise', data: null };
        });
    },

    generateVideoUploadURL: async (fileData: { fileType: string; filename: string }) => {
      return httpClient
        .post<IAPIResponse<{ url: string; name: string }>>(`/exercises/upload/video/generate-url`, fileData)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get video url', data: null };
        });
    },

    convertAndUploadVideo: async (formData: FormData) => {
      return httpClient
        .post<IAPIResponse<{ url: string; filename: string }>>(`/exercises/upload/video/convert-upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        })
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get video url', data: null };
        });
    },

    generateScreenshot: async (type: 'image' | 'video', formData: FormData) => {
      return httpClient
        .post(`/exercises/upload/${type}/generate-screenshot`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
          responseType: 'blob',
        })
        .then((response) => {
          return {
            status: true,
            message: 'Succesfully generated',
            data: new File([response.data], `${formData.get('name')}.png`, { type: response.headers['Content-Type']?.toString() ?? 'image/png' }),
          };
        })
        .catch(() => {
          return { status: false, message: 'Failed to get video url', data: null };
        });
    },

    checkStorage: async (data = {}) => {
      return httpClient
        .post<IAPIResponse<null>>('/exercises/upload/verify/storage', data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to add exercise', data: null };
        });
    },

    setUploadedVideoName: async (filename: string, exerciseId: string, isAssigned: boolean, isConverting: boolean = false) => {
      return httpClient
        .put<IAPIResponse<{ url: string; name: string }>>(`/exercises/upload/video/filename`, { filename, exerciseId, isAssigned, isConverting })
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to set video name', data: null };
        });
    },
  };
};
export default exerciseService;
