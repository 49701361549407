import { Navigate } from 'react-router-dom';

import { Role } from '../interfaces/auth';
import { logout } from '../service';
import { validateToken } from '../utils/auth-utils';
import { PatientRoutes } from './patient-routes';
import { PractitionerRoutes } from './practitioner';

interface Props {
  component?: React.ComponentType;
  access?: Role;
  path?: string;
  isIndex?: boolean;
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent, access, isIndex }) => {
  const { isAuth, expiresIn, role } = validateToken();

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  if (isIndex && !RouteComponent) {
    return role !== Role.PATIENT ? (
      <Navigate to={`${PractitionerRoutes[0].path}`} />
    ) : (
      <Navigate to={`${PatientRoutes[0].path}`} />
    );
  }

  if (!RouteComponent) {
    return <Navigate to="/404" />;
  }

  if (role === access) {
    setTimeout(() => logout(), expiresIn - Date.now());
    return <RouteComponent />;
  }

  return <Navigate to="/404" />;
};

export default PrivateRoute;
