import { Slide, ThemeOptions } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { FC } from 'react';
import { Colors, SizeUnits } from './types';

declare module '@mui/material/styles' {
  interface CustomTheme {
    colors?:  Colors;
    fontSizes: SizeUnits;
  }
  
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

const theme = (themeOptions?: ThemeOptions) => createTheme(themeOptions);

interface ThemesProps {
  children?: React.ReactNode;
  themeOptions?: ThemeOptions;
}

const Themes: FC<ThemesProps> = (props) => {
  return (
    <ThemeProvider theme={theme(props.themeOptions)}>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        TransitionComponent={Slide as any}
        autoHideDuration={3000}
      >
        {props.children}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Themes;
