import { AxiosInstance } from 'axios';

import { IAPIResponse } from '../interfaces';
import { IProgram, IProgramCollection, IProgramResponse } from '../interfaces/program';
import { SeriesWithAssignedExercise } from '../interfaces/series';

const programService = (httpClient: AxiosInstance) => {
  return {
    createProgram: async (program: IProgram) => {
      return httpClient
        .post<IAPIResponse<IProgramCollection>>('/program', program)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Creating program failed', data: null };
        });
    },

    getProgram: async (id: string) => {
      return httpClient
        .get<IAPIResponse<IProgramCollection>>(`/program/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Filed to get program', data: null };
        });
    },

    getPrograms: async () => {
      return httpClient
        .get<IAPIResponse<IProgramCollection[]>>(`/program`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Filed to get program', data: null };
        });
    },

    deleteProgram: async (id: string) => {
      return httpClient
        .delete<IAPIResponse<IProgramResponse>>(`/program/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Filed to delete program', data: null };
        });
    },

    getPatientPrograms: async (id: string) => {
      return httpClient
        .get<IAPIResponse<IProgramResponse>>(`/program/patient/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Filed to get program', data: null };
        });
    },

    addWeekToProgram: async (id: string, week: number) => {
      return httpClient
        .post<IAPIResponse<IProgramCollection>>(`/program/${id}/add-week`, { week })
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'File to add new week.', data: null };
        });
    },

    getSeriesBySeriesIdAndWeek: async (programId: string, seriesId: string, week: number) => {
      return httpClient
        .get<IAPIResponse<SeriesWithAssignedExercise>>(`/program/${programId}/series/${seriesId}/week/${week}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to update series ', data: null };
        });
    },
  };
};

export default programService;
