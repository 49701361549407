import { AxiosInstance } from 'axios';
import { IAPIResponse } from '../interfaces';
import { IUserRes } from '../interfaces/user';

const userService = (httpClient: AxiosInstance) => {
  return {
    getUser: async () => {
      return httpClient
        .get<IAPIResponse<IUserRes>>(`/user/`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get data', data: undefined };
        });
    },
  };
};

export default userService;
