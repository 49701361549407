import { AxiosInstance } from 'axios';

import { IAPIResponse } from '../interfaces';
import { IPractitioner, IPatientCollection, IPatientResponse, IPractitionerWithCountRes } from '../interfaces/patient';

const patientService = (httpClient: AxiosInstance) => {
  return {
    getPatients: async () => {
      return httpClient
        .get<IAPIResponse<IPractitioner[]>>('/practitioners/patients')
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read patients', data: [] };
        });
    },

    addPatient: async (patient: FormData) => {
      return httpClient
        .post<IAPIResponse<IPatientResponse>>('/practitioners/patient', patient)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to create patient', data: null, validation: [] };
        });
    },

    getPatient: async (userId: string) => {
      return httpClient
        .get<IAPIResponse<IPatientCollection>>(`/practitioners/patient/${userId}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read patient details', data: null };
        });
    },

    editPatient: async (userId: string, patientData: {}) => {
      return httpClient
        .patch<IAPIResponse<null>>(`/practitioners/patient/edit/${userId}`, patientData)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Updating patient details failed', data: null };
        });
    },

    deletePatient: async (userId: string) => {
      return httpClient
        .delete<IAPIResponse<null>>(`/practitioners/patient/${userId}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to delete patient', data: null };
        });
    },

    deactivatePatient: async (userId: string, active: boolean) => {
      return httpClient
        .put<IAPIResponse<null>>(`/practitioners/patient/${userId}`, { isActive: active })
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Patient deactivation failed', data: null };
        });
    },

    getPatientData: async () => {
      return httpClient
        .get<IAPIResponse<IPatientCollection>>(`/patients`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read patient details', data: null };
        });
    },

    resendMail: async (userId: string) => {
      return httpClient
        .patch<IAPIResponse<null>>(`/practitioners/patient/resend-mail/${userId}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed', data: null };
        });
    },
    getPatientBySpecialistId: async (userId: string) => {
      return httpClient
        .get<IAPIResponse<IPractitioner[]>>(`/practitioners/${userId}/patients`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read patients', data: [] };
        });
    },

    getPractitionersWithCount: async () => {
      return httpClient
        .get<IAPIResponse<IPractitionerWithCountRes>>('/practitioners')
        .then(({ data }) => data)
        .catch(() => {
          return { status: false, message: 'Failed to get practitioners', data: undefined };
        });
    },
  };
};
export default patientService;
