import { AxiosInstance } from 'axios';
import { SettingsTypes } from '../enums';

import { IAPIResponse } from '../interfaces';
import { Aesthetics } from '../interfaces/settings';

const settingsService = (httpClient: AxiosInstance) => {
  return {
    getCurrentSettingsByType: async function <T = Aesthetics>(params = { type: SettingsTypes.MYACCOUNT }) {
      const query = { params };
      return httpClient
        .get<IAPIResponse<T>>('/settings', query)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get the details', data: {} };
        });
    },
    updateCurrentTheme: async (updatedColorCodes: Aesthetics) => {
      return httpClient
        .post<IAPIResponse<Aesthetics>>('/settings', updatedColorCodes)
        .then((res) => {
          return res;
        })
        .catch(() => {
          return { status: false, message: 'Failed to update', data: null, validation: [] };
        });
    },

    updateCurrentLogo: async (updatedLogo: FormData) => {
      return httpClient
        .put<IAPIResponse<Aesthetics>>('/practitioners/organization/logo', updatedLogo)
        .then((res) => {
          return res;
        })
        .catch(() => {
          return { status: false, message: 'Failed to upload', data: null };
        });
    },
  };
};

export default settingsService;
