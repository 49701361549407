/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAuth } from '../../interfaces/auth';
import { authService } from '../../service';
import { getAuthStorage } from '../../utils/storage-util';

const storage = getAuthStorage();

const initialState: IAuth = storage ? { status: true, message: '', loading: false, data: storage } : { status: false, message: '', loading: false };

export const loginAsync = createAsyncThunk<IAuth, { email: string; password: string; orgId: string }>('auth/login', async ({ email, password, orgId }): Promise<IAuth> => {
  try {
    const res = await authService.login(email, password, orgId);
    if (!res.status) {
      return { status: false, message: res?.message || 'Login failed.' };
    }

    return res;
  } catch (err: any) {
    return { status: false, message: err.message as string };
  }
});

export const loginAsAsync = createAsyncThunk<IAuth, { email: string; otp: string; orgId: string; token: string }>(
  'auth/login-as',
  async ({ email, otp, orgId, token }): Promise<IAuth> => {
    try {
      const res = await authService.loginAs(email, otp, orgId, token);
      return res;
    } catch (err: any) {
      return { status: false, message: err.message as string };
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    value: initialState,
  },
  reducers: {
    setAuth: (state, action: PayloadAction<IAuth>) => {
      state.value = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsync.pending, (state) => {
      state.value = { ...initialState, loading: true };
    });
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.value = { ...action.payload, loading: false };
    });
    builder.addCase(loginAsAsync.pending, (state) => {
      state.value = { ...initialState, loading: true };
    });
    builder.addCase(loginAsAsync.fulfilled, (state, action) => {
      state.value = { ...action.payload, loading: false };
    });
  },
});

export const { setAuth } = authSlice.actions;

export default authSlice.reducer;
