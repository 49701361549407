import { AES, enc } from 'crypto-js';

import { KEY_STRING } from '../config/api-config';
import { IAuthToken, IUserData } from '../interfaces';

const KEY = 'session';

export const setAuthStorage = (auth: IAuthToken): void => {
  try {
    localStorage.setItem(KEY, AES.encrypt(JSON.stringify(auth), KEY_STRING).toString());
    return;
  } catch (err) {
    return;
  }
};

export const getAuthStorage = (): IAuthToken | null => {
  try {
    const auth = localStorage.getItem(KEY);
    if (!auth) {
      return null;
    }

    const result = JSON.parse(AES.decrypt(auth, KEY_STRING).toString(enc.Utf8));
    return result;
  } catch (err) {
    return null;
  }
};

export const clearStorage = (key = KEY) => {
  localStorage.removeItem(key);
};

export const setSessionStorage = (userData: IUserData) => {
  try {
    sessionStorage.setItem(KEY, JSON.stringify(userData));
    return;
  } catch (err) {
    return;
  }
};

export const getSessionStorage = (): IUserData | null => {
  try {
    const data = sessionStorage.getItem(KEY);
    const result = JSON.parse(typeof data === 'string' ? data : '');

    return result;
  } catch (err) {
    return null;
  }
};

export const clearSessionStorage = () => {
  sessionStorage.removeItem(KEY);
};
