import { ISupportTicket } from './../interfaces/support';
import { AxiosInstance } from 'axios';

import { IAPIResponse } from '../interfaces';

const supportService = (httpClient: AxiosInstance) => {
  return {
    getUserTickets: async () => {
      return httpClient
        .get<IAPIResponse<ISupportTicket[]>>(`/user/support`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get user tickets.', data: undefined };
        });
    },

    createTicket: async (data: {}) => {
      return httpClient
        .post<IAPIResponse<ISupportTicket[]>>(`/user/support`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get user tickets.', data: undefined };
        });
    },

    createReply: async (id: string, data: {}) => {
      return httpClient
        .put<IAPIResponse<ISupportTicket[]>>(`/user/support/reply/${id}`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get user tickets.', data: undefined };
        });
    },

    deleteReply: async (ticketId: string, replyId: string) => {
      return httpClient
        .delete<IAPIResponse<ISupportTicket[]>>(`/user/support/reply/${ticketId}/${replyId}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get user tickets.', data: undefined };
        });
    },

    readTicket: async (id: string, data = {}) => {
      return httpClient
        .put<IAPIResponse>(`/user/support/read-status/${id}`, data)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to update read status.', data: undefined };
        });
    },
  };
};

export default supportService;
