import { AxiosInstance } from 'axios';

import { IAPIResponse, IUpdatePlanRes } from '../interfaces';
import { ICustomPLanAlertRes, IPlans } from '../interfaces/plans';

const planService = (httpClient: AxiosInstance) => {
  return {
    getPlansWithId: async (planId: string, priceListId?: {}) => {
      return httpClient
        .get<IAPIResponse<IPlans>>(`/plans/${planId}/`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read patients', data: undefined };
        });
    },

    getPlansWithIdAndPriceListId: async (planId: string, priceListId: string) => {
      return httpClient
        .get<IAPIResponse<IPlans>>(`/plans/${planId}/${priceListId}`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read patients', data: undefined };
        });
    },

    getPlans: async () => {
      return httpClient
        .get<IAPIResponse<IPlans[]>>('/plans')
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read plan details', data: null };
        });
    },

    getUserPlan: async () => {
      return httpClient
        .get<IAPIResponse<IPlans>>(`/practitioners/user-plan`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read patients', data: undefined };
        });
    },

    updatePlan: async (planId: string, data: {}) => {
      return httpClient
        .put<IAPIResponse<IUpdatePlanRes>>(`plans/subscription/${planId}`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'plan update failed', data: null };
        });
    },

    requestPlan: async (data: {}) => {
      return httpClient
        .post<IAPIResponse>(`plans/request-plan/`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'plan update failed', data: null };
        });
    },

    customPlanAlert: async () => {
      return httpClient
        .get<IAPIResponse<ICustomPLanAlertRes>>(`/practitioners/alert`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read patients', data: undefined };
        });
    },

    createAddonSubscription: async (data: { packId: string; packType: string; packValue?: string }) => {
      return httpClient
        .post<IAPIResponse>('plans/subscription/addons', data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to create addon', data: false };
        });
    },

    cancelSubscription: async () => {
      return httpClient
        .put<IAPIResponse>('plans/subscription/cancel')
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to cancel subscription', data: false };
        });
    },

    resumeSubscription: async () => {
      return httpClient
        .put<IAPIResponse>('plans/subscription/resume')
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to resume subscription', data: false };
        });
    },
  };
};

export default planService;
