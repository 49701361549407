/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAestheticsInfo } from '../../interfaces/settings';
import { settingsService } from '../../service';

interface AestheticsAction {
  payload: IAestheticsInfo;
}
const defaultSchema = {
  message: 'success',
  status: true,
  data: {
    settings: {
      primaryColor: '#C1E0E7',
      secondaryColor: '#3B3E42',
      primaryFont: 'Bilo',
      secondaryFont: 'Lust',
      buttonBackground: '#C1E0E7',
      buttonIcon: '#C1E0E7',
      buttonText: '#3B3E42',
    },
    type: 'MYACCOUNT',
  },
  loading: false,
};

const initialState: IAestheticsInfo = { status: false, message: '', loading: true };

export const getAestheticsAsync = createAsyncThunk('/settings/my-account', async (): Promise<any> => {
  try {
    const res = await settingsService.getCurrentSettingsByType();
    if (!res.data) {
      return defaultSchema;
    }

    return res;
  } catch (err: any) {
    return { status: false, message: err.message as string, loading: false };
  }
});

export const aestheticsSlice = createSlice({
  name: 'aesthetics',
  initialState: {
    value: initialState,
  },
  reducers: {
    getThemeSchema: (state, action: AestheticsAction) => {
      state.value = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAestheticsAsync.pending, (state) => {
      state.value = { ...initialState, loading: true };
    });
    builder.addCase(getAestheticsAsync.fulfilled, (state, action) => {
      state.value = { ...action.payload, loading: false };
    });
  },
});

export const { getThemeSchema } = aestheticsSlice.actions;

export default aestheticsSlice.reducer;
