import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISeriesExercise } from '../../interfaces/series';

const initialState: { value: ISeriesExercise[] } = { value: [] };

export const seriesExerciseSaveReducerSlice = createSlice({
  name: 'series-slice-save',
  initialState,
  reducers: {
    addToSaveExercise: (state, action: PayloadAction<ISeriesExercise>) => {
      state.value.push(action.payload);
    },
    deleteFromSaveExercise: (state, action: PayloadAction<string>) => {
      state.value = state.value.filter((exercise) => exercise.exerciseId !== action.payload);
    },
    resetSaveExercises: (state) => {
      state.value = [];
    },
  },
});

export const { addToSaveExercise, deleteFromSaveExercise, resetSaveExercises } = seriesExerciseSaveReducerSlice.actions;
export default seriesExerciseSaveReducerSlice.reducer;
