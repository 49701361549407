import { StandardTextFieldProps, SxProps } from '@mui/material';
import { Plan } from './auth';
import { IUserOrganization } from './organization';

export type Obj<T = unknown> = Record<string, T>;

export interface IAPIResponse<D = unknown> {
  message: string;
  status: boolean;
  statusCode?: number;
  validation?: IValidation[];
  data?: D;
}

export interface IAuthToken {
  token: string;
  userId: string;
  role: string;
  expiresIn: string;
  name: string;
  email: string;
  plan: Plan;
  planId: string;
}

export interface FormDataValues {
  name: string;
  value: string | Blob;
}

export enum FieldType {
  EMAIL = 'email',
  STRING = 'string',
  PASSWORD = 'password',
  NUMBER = 'number',
  MOBILE = 'mobile',
}

export interface FormValidator {
  name: string;
  value: string | number;
  type: FieldType;
  length?: number;
}

export interface IUserData {
  email: string;
  name: string;
  clinics: IUserOrganization[] | string;
}
export interface IIntensityType {
  type: string;
}

export interface ITabsHeaderData {
  label: string;
  url: string;
}

export interface IValidation {
  field?: string;
  message?: string;
}

export interface IPaymentData {
  planId: string;
  priceListId: string;
  orgId: string;
  paymentMethod: string;
  cardName: string;
  promotionCode?: string;
  customerEmail: string;
  address: { line1: string; postal_code: string; country: string; state: string };
}

export interface ISignUpRes extends IAPIResponse {
  data?: IAuthToken;
}

export interface IUpdatePlanRes extends IAPIResponse {
  data: IAuthToken;
}

export interface IPaymentRes {
  status: string;
  subscriptionId: string;
  priceId: string;
  planObjectId: string;
  clientSecret: string;
}

export interface IPromoRes {
  id: string;
  code: string;
  amount_off: number | null;
  percent_off: number | null;
}

export interface TextFieldProps extends StandardTextFieldProps {
  labelSx?: SxProps;
  inputFocus?: boolean;
}

export interface IReadStatus {
  readStatus?: { support: false; user: true };
}
