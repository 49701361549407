import { IDeletedExercise, IReOrderSeriesData } from './../interfaces/series';
import { AxiosInstance } from 'axios';
import { IAPIResponse } from '../interfaces';
import { IProgramLibraryResponse, IProgramWithoutUser } from '../interfaces/program';

const libraryService = (httpClient: AxiosInstance) => ({
  getProgram: async () => {
    return httpClient
      .get<IAPIResponse<IProgramWithoutUser[]>>('/program-library')
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return { status: false, message: 'Failed to get program.', data: null };
      });
  },

  getProgramWithId: async (id: string) => {
    return httpClient
      .get<IAPIResponse<IProgramLibraryResponse>>(`/program-library/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return { status: false, message: 'Failed to get program.', data: null };
      });
  },

  updateProgramLibrary: async (id: string, data: {}) => {
    return httpClient
      .put<IAPIResponse>(`program-library/${id}`, data)
      .then((res) => res.data)
      .catch(() => ({ status: false, message: 'Failed to update program.' }));
  },

  deleteProgramWithId: async (id: string) => {
    return httpClient
      .delete<IAPIResponse>(`/program-library/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return { status: false, message: 'Failed to delete program.', data: null };
      });
  },

  deleteSeries: async (id: string, data: string[] = []) => {
    return httpClient
      .put<IAPIResponse>(`/program-library/${id}/series`, data)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return { status: false, message: 'Failed to delete series.', data: null };
      });
  },

  deleteExercise: async (id: string, data: IDeletedExercise[] = []) => {
    return httpClient
      .put<IAPIResponse>(`/program-library/${id}/exercise`, data)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return { status: false, message: 'Failed to delete exercise.', data: null };
      });
  },

  reorderSeries: async (id: string, data: IReOrderSeriesData[]) => {
    return httpClient
      .put<IAPIResponse>(`/program-library/${id}/order-number`, data)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return { status: false, message: 'Failed to delete exercise.', data: null };
      });
  },
});

export default libraryService;
