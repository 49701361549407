/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IUserInfo } from '../../interfaces/user';
import { userService } from '../../service';

interface IUserAction {
  payload: IUserInfo;
}

const initialState: IUserInfo = { status: false, message: '', loading: true };

export const getInfoAsync = createAsyncThunk('', async (): Promise<IUserInfo> => {
  try {
    const data = await userService.getUser();
    return { ...data, loading: true };
  } catch (err: any) {
    return { status: false, message: err.message as string, loading: false };
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: initialState,
  },
  reducers: {
    getInfo: (state, action: IUserAction) => {
      state.value = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInfoAsync.pending, (state) => {
      state.value = { ...initialState, loading: true };
    });
    builder.addCase(getInfoAsync.fulfilled, (state, action) => {
      state.value = { ...action.payload, loading: false };
    });
  },
});

export const { getInfo } = userSlice.actions;

export default userSlice.reducer;
