import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../interfaces/redux/store';
import { IUserInfo } from '../interfaces/user';
import { getInfoAsync } from '../state/reducers/user-reducer';
import AppLoader from './loader/AppLoader';

interface RootProps {
  children?: React.ReactNode;
}

const Root: FC<RootProps> = (props) => {
  const { data: info, loading } = useSelector<IRootState, IUserInfo>(({ user }) => user.value);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInfoAsync());
  }, [dispatch]);

  if (loading) {
    return <AppLoader />;
  }

  return <>{props.children}</>;
};

export default Root;
