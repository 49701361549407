export enum Title {
  NEED_HELP = 'Need help',
  COMPLETED_PROGRAM = 'Completed program',
  UNABLE_TO_COMPLETE_PROGRAM = 'Unable to complete program',
}

export enum PlanParamTypes {
  PRACTITIONER_COUNT = 'PRACTITIONER_COUNT',
  PATIENT_COUNT = 'PATIENT_COUNT',
  PATIENT_COUNT_PER_PRACTITIONER = 'PATIENT_COUNT_PER_PRACTITIONER',
  STORAGE = 'STORAGE',
  IMAGE_COUNT = 'IMAGE_COUNT',
  VIDEO_COUNT = 'VIDEO_COUNT',
}

export enum PlanParamUnits {
  MB = 'MB',
  COUNT = 'NUMBERS',
}

export enum SettingsTypes {
  MYACCOUNT = 'MYACCOUNT',
  STORAGE = 'STORAGE',
  PRACTITIONER_COUNT = 'PRACTITIONER_COUNT',
}

export enum StripeStatus {
  DELETED = 'deleted',
  DRAFT = 'draft',
  OPEN = 'open',
  UNCOLLECTIBLE = 'uncollectible',
  VOID = 'void',
  FAILED = 'failed',
  SUCCESS = 'paid',
  PENDING = 'pending',
  FINALIZATION_FAILED = 'finalization failed',
}

export enum TicketStatus {
  OPEN = 'open',
  CLOSE = 'close',
  PENDING = 'pending',
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
}
